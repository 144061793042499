exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-animation-baby-page-index-tsx": () => import("./../../../src/templates/AnimationBabyPage/index.tsx" /* webpackChunkName: "component---src-templates-animation-baby-page-index-tsx" */),
  "component---src-templates-animation-page-index-tsx": () => import("./../../../src/templates/AnimationPage/index.tsx" /* webpackChunkName: "component---src-templates-animation-page-index-tsx" */),
  "component---src-templates-article-page-index-tsx": () => import("./../../../src/templates/ArticlePage/index.tsx" /* webpackChunkName: "component---src-templates-article-page-index-tsx" */),
  "component---src-templates-beach-club-page-index-tsx": () => import("./../../../src/templates/BeachClubPage/index.tsx" /* webpackChunkName: "component---src-templates-beach-club-page-index-tsx" */),
  "component---src-templates-bike-page-index-tsx": () => import("./../../../src/templates/BikePage/index.tsx" /* webpackChunkName: "component---src-templates-bike-page-index-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/BlogPage/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-blu-secret-page-index-tsx": () => import("./../../../src/templates/BluSecretPage/index.tsx" /* webpackChunkName: "component---src-templates-blu-secret-page-index-tsx" */),
  "component---src-templates-blu-spa-page-index-tsx": () => import("./../../../src/templates/BluSpaPage/index.tsx" /* webpackChunkName: "component---src-templates-blu-spa-page-index-tsx" */),
  "component---src-templates-business-page-index-tsx": () => import("./../../../src/templates/BusinessPage/index.tsx" /* webpackChunkName: "component---src-templates-business-page-index-tsx" */),
  "component---src-templates-concept-page-index-tsx": () => import("./../../../src/templates/ConceptPage/index.tsx" /* webpackChunkName: "component---src-templates-concept-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-corporate-events-page-index-tsx": () => import("./../../../src/templates/CorporateEventsPage/index.tsx" /* webpackChunkName: "component---src-templates-corporate-events-page-index-tsx" */),
  "component---src-templates-day-spa-page-index-tsx": () => import("./../../../src/templates/DaySpaPage/index.tsx" /* webpackChunkName: "component---src-templates-day-spa-page-index-tsx" */),
  "component---src-templates-exclusive-club-page-index-tsx": () => import("./../../../src/templates/ExclusiveClubPage/index.tsx" /* webpackChunkName: "component---src-templates-exclusive-club-page-index-tsx" */),
  "component---src-templates-family-page-index-tsx": () => import("./../../../src/templates/FamilyPage/index.tsx" /* webpackChunkName: "component---src-templates-family-page-index-tsx" */),
  "component---src-templates-faq-page-index-tsx": () => import("./../../../src/templates/FaqPage/index.tsx" /* webpackChunkName: "component---src-templates-faq-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-landing-page-index-tsx": () => import("./../../../src/templates/LandingPage/index.tsx" /* webpackChunkName: "component---src-templates-landing-page-index-tsx" */),
  "component---src-templates-lounge-page-index-tsx": () => import("./../../../src/templates/LoungePage/index.tsx" /* webpackChunkName: "component---src-templates-lounge-page-index-tsx" */),
  "component---src-templates-meeting-page-index-tsx": () => import("./../../../src/templates/MeetingPage/index.tsx" /* webpackChunkName: "component---src-templates-meeting-page-index-tsx" */),
  "component---src-templates-offer-page-index-tsx": () => import("./../../../src/templates/OfferPage/index.tsx" /* webpackChunkName: "component---src-templates-offer-page-index-tsx" */),
  "component---src-templates-offers-page-index-tsx": () => import("./../../../src/templates/OffersPage/index.tsx" /* webpackChunkName: "component---src-templates-offers-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-press-page-index-tsx": () => import("./../../../src/templates/PressPage/index.tsx" /* webpackChunkName: "component---src-templates-press-page-index-tsx" */),
  "component---src-templates-private-party-page-index-tsx": () => import("./../../../src/templates/PrivatePartyPage/index.tsx" /* webpackChunkName: "component---src-templates-private-party-page-index-tsx" */),
  "component---src-templates-quotation-page-index-tsx": () => import("./../../../src/templates/QuotationPage/index.tsx" /* webpackChunkName: "component---src-templates-quotation-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-room-page-index-tsx": () => import("./../../../src/templates/RoomPage/index.tsx" /* webpackChunkName: "component---src-templates-room-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-social-wall-page-index-tsx": () => import("./../../../src/templates/SocialWallPage/index.tsx" /* webpackChunkName: "component---src-templates-social-wall-page-index-tsx" */),
  "component---src-templates-weddings-page-index-tsx": () => import("./../../../src/templates/WeddingsPage/index.tsx" /* webpackChunkName: "component---src-templates-weddings-page-index-tsx" */)
}

